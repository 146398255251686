import { TemplateLocaleEnum } from '__generated__/types';
import { TagView, UserTagView } from '@xometry/ui';
import { Form, FormInstance, Select, SelectProps, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import clsx from 'clsx';
import { TemplateModalFormFieldEnum, TemplateModalFormValues } from 'components/TemplatesModal/TemplateModal.types';
import { TemplatesAttachments } from 'components/TemplatesModal/TemplatesAttachments/TemplatesAttachments';
import { UsersSelect } from 'components/TemplatesModal/UsersSelect/UsersSelect';
import { EmailsField } from 'components/UI/Form/EmailsField';
import { TemplateDirectionLabel } from 'components/UI/TemplateDirectionLabel/TemplateDirectionLabel';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { TemplateFragment } from 'utils/graphql/fragments/__generated__/template';
import { TemplateModalQuery } from 'utils/graphql/queries/__generated__/templateModal';

import s from './TemplatesModalForm.module.less';
import { useSuggestEmails } from './useSuggestEmails';
import { useTemplatesModalForm } from './useTemplatesModalForm';

interface Props {
  form: FormInstance<TemplateModalFormValues>;
  templateModalData: TemplateModalQuery;
  selectedCategoryId: string | null;
  setSelectedCategoryId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTemplate: TemplateFragment | null;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<TemplateFragment | null>>;
  isVisibleToExternals: boolean;
  isMobile: boolean;
}

const renderEmailTag: SelectProps['tagRender'] = ({ onClose, value }) => (
  <TagView onCrossClick={onClose}>{value}</TagView>
);

const TemplatesModalFormComponent: FC<Props> = ({
  form,
  templateModalData,
  selectedCategoryId,
  setSelectedCategoryId,
  selectedTemplate,
  setSelectedTemplate,
  isVisibleToExternals,
  isMobile,
}) => {
  const {
    emailFieldsVisible,
    setEmailFieldsVisible,
    getUserById,
    renderedTemplateData,
    renderedTemplateLoading,
    isEmailSendingAvailable,
    isUploadingFilesAvailable,
    templateMapByConstantName,
    localeOptions,
    attachments,
    setAttachments,
    handleLocaleChange,
    handleMentionSelect,
    handleMentionDeselect,
    handleToChange,
    handleCcChange,
    handleBccChange,
    handleCategoryChange,
    handleTemplateConstantNameChange,
  } = useTemplatesModalForm({
    form,
    templateModalData,
    selectedCategoryId,
    setSelectedCategoryId,
    selectedTemplate,
    setSelectedTemplate,
  });

  const emailSelectProps = useSuggestEmails({
    organizationId: templateModalData.messengerSourceRelatedObjects?.person?.organizationId,
  });

  const mods = { [s.isMobile]: isMobile };

  return (
    <Form form={form} className={clsx(s.form, { ...mods })}>
      <div className={clsx(s.topRow, { ...mods })}>
        <Form.Item
          name={TemplateModalFormFieldEnum.Category}
          label="Template category:"
          className={clsx(s.verticalFormItem, { ...mods })}
        >
          <Select
            placeholder="Template category"
            onChange={handleCategoryChange}
            getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
            options={templateModalData?.messengerTemplateCategories.map(({ id, title }) => ({
              value: id,
              label: title,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={TemplateModalFormFieldEnum.TemplateConstantName}
          label="Template name:"
          className={clsx(s.verticalFormItem, { ...mods })}
          shouldUpdate
        >
          <Select
            placeholder="Template name"
            onChange={handleTemplateConstantNameChange}
            getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
            options={Object.entries(templateMapByConstantName).map(([templateConstantName, template]) => {
              const curTemplate =
                template[form.getFieldValue(TemplateModalFormFieldEnum.Locale) as TemplateLocaleEnum] ||
                Object.values(template)[0];

              const { name, direction } = curTemplate || {};

              return {
                value: templateConstantName,
                label: (
                  <div className={s.templateNameOption}>
                    <div className={s.templateNameOptionName}>{name || templateConstantName}</div>
                    {direction ? <TemplateDirectionLabel direction={direction} hideLabel /> : null}
                  </div>
                ),
              };
            })}
          />
        </Form.Item>
        <Form.Item
          name={TemplateModalFormFieldEnum.Locale}
          label="Locale:"
          className={clsx(s.verticalFormItem, s.locale, { ...mods })}
          shouldUpdate
        >
          <Select
            placeholder="Locale"
            getPopupContainer={(trigger: HTMLElement) => trigger.parentElement as HTMLElement}
            options={localeOptions}
            onChange={handleLocaleChange}
          />
        </Form.Item>
      </div>
      <div className={s.selects}>
        <div className={clsx(s.selectRow, { ...mods })}>
          <Form.Item label="Subscribers:">
            <div className={clsx(s.subscribersList, { ...mods })}>
              {templateModalData.messengerSource.followingUserIds.map((userId) => {
                const user = getUserById(userId);

                if (!user) {
                  return null;
                }

                return (
                  <UserTagView
                    key={userId}
                    className={s.userTag}
                    name={user.name}
                    imageUrl={user.imageUrl || ''}
                    size="small"
                  />
                );
              })}
            </div>
          </Form.Item>
        </div>
        {isVisibleToExternals ? null : (
          <div className={clsx(s.selectRow, { ...mods })}>
            <Form.Item name={TemplateModalFormFieldEnum.Mentions} label="Mention:">
              <UsersSelect
                onSelect={handleMentionSelect}
                onDeselect={handleMentionDeselect}
                placeholder="Select users to mention"
              />
            </Form.Item>
          </div>
        )}
        {isEmailSendingAvailable ? (
          <>
            <div className={clsx({ [s.selectRow]: true, [s.hidden]: !emailFieldsVisible, ...mods })}>
              <Form.Item name={TemplateModalFormFieldEnum.To} label="To:">
                <EmailsField
                  disabled={renderedTemplateLoading}
                  className={s.emailsSelector}
                  tagRender={renderEmailTag}
                  onChange={handleToChange}
                  {...emailSelectProps}
                />
              </Form.Item>
            </div>
            <div className={clsx({ [s.selectRow]: true, [s.hidden]: !emailFieldsVisible, ...mods })}>
              <Form.Item name={TemplateModalFormFieldEnum.Cc} label="CC:">
                <EmailsField
                  disabled={renderedTemplateLoading}
                  className={s.emailsSelector}
                  tagRender={renderEmailTag}
                  onChange={handleCcChange}
                  {...emailSelectProps}
                />
              </Form.Item>
            </div>
            <div className={clsx({ [s.selectRow]: true, [s.hidden]: !emailFieldsVisible, ...mods })}>
              <Form.Item name={TemplateModalFormFieldEnum.Bcc} label="BCC:">
                <EmailsField
                  disabled={renderedTemplateLoading}
                  className={s.emailsSelector}
                  tagRender={renderEmailTag}
                  onChange={handleBccChange}
                  {...emailSelectProps}
                />
              </Form.Item>
            </div>
          </>
        ) : null}
      </div>
      {isEmailSendingAvailable ? (
        <div className={s.switcherRow}>
          <Switch
            title="Enable additional fields"
            checked={emailFieldsVisible}
            onChange={(checked) => {
              setEmailFieldsVisible(checked);
            }}
            size="small"
          />
          Enable additional fields
        </div>
      ) : null}

      {isUploadingFilesAvailable ? (
        <TemplatesAttachments
          attachments={attachments}
          setAttachments={setAttachments}
          form={form}
          readOnly={Boolean(renderedTemplateData?.messengerRenderTemplate?.readOnly)}
          isMobile={isMobile}
        />
      ) : null}

      <Form.Item name={TemplateModalFormFieldEnum.Content}>
        <TextArea
          disabled={renderedTemplateLoading}
          className={clsx(s.textarea, { ...mods })}
          readOnly={renderedTemplateData?.messengerRenderTemplate?.readOnly}
        />
      </Form.Item>
    </Form>
  );
};

export const TemplatesModalForm = observer(TemplatesModalFormComponent);
